import React, { useState, useEffect } from 'react';
import './App.css';
import { Configuration, OpenAIApi } from "openai";

// Configure the conection OpenAI's API
const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const configuration = new Configuration({
  apiKey: API_KEY,
});
const openai = new OpenAIApi(configuration);



function App() {
  const [inited, setInited] = useState(false);
  const [memory, setMemory] = useState('');
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [promptText, setPromptText] = useState('');
  const [response, setResponse] = useState('');

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  function changeLabel() {
    const label = document.querySelector('label');
    const textarea = document.querySelector('textarea');
    label.textContent = 'How can I help?';
    if (textarea) {
      label.setAttribute('for', textarea.id);
    }
  }

  function addSTMemory (moment) {
    setMemory(memory + " " + moment);
    console.log("Memory: " + memory);
  }

  // How do we know when it's a new topic? 

  useEffect(() => {
    console.log("Memory: " + memory);
  }, [memory]);
  

  // fix deleting textarea when changeLabel is called

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!inited) {
      setName(text);
      addSTMemory("Address me as " + text + " in your response.");
      setInited(true);
      setText('');
      //changeLabel();
    } else {
      try {
        console.log("trying")
        // Call the OpenAI API to generate a response based on the user's input
        const result = await openai.createCompletion({
          model: "text-davinci-003",
          prompt: memory + text,
          temperature: 0.7,
          max_tokens: 128,
          top_p: 1.0,
          frequency_penalty: 0.0,
          presence_penalty: 0.0,
        });
        
        // Set the response to the generated text
        //console.log(result.data.choices[0].text.trim());
        setResponse(result.data.choices[0].text.trim());
        addSTMemory(text);
        setText('');
      } catch (err) {
        console.error(err);
        console.log(err.response)
        setResponse('Error generating response. Please try again later.');
      }
    }
  };

  return (
    <div className="App">
      <nav>
        <button className="hamburger-menu">&#9776;</button>
      </nav>
      <form onSubmit={handleSubmit} className="text-entry-form">
        <label htmlFor="textArea">
          Let's start. What's your name?
          <textarea
            id="textArea"
            value={text}
            onChange={handleTextChange}
            rows="10"
            cols="50"
            placeholder="Type here..."
            autoFocus
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
      <div className="response">{response}</div>
    </div>
  );
}

export default App;
